export const fbConfig =
  import.meta.env.VITE_ENV === 'production'
    ? {
        apiKey: 'AIzaSyA1fo8ZCrXFG7OYC7c3b1ZSBnWLZU-7xDg',
        authDomain: 'raw-figures.firebaseapp.com',
        databaseURL: 'https://raw-figures.firebaseio.com',
        projectId: 'raw-figures',
        storageBucket: 'raw-figures.appspot.com',
        messagingSenderId: '487392708736',
        appId: '1:487392708736:web:d4e58a86744fa700a73e65',
        measurementId: 'G-57MZRKQXCW',
      }
    : {
        apiKey: 'AIzaSyAztkskDfafjHufTiNcHdsLg7cTLYM2Jto',
        authDomain: 'raw-dev-890df.firebaseapp.com',
        databaseURL:
          'https://raw-dev-890df-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'raw-dev-890df',
        storageBucket: 'raw-dev-890df.appspot.com',
        messagingSenderId: '45297446453',
        appId: '1:45297446453:web:12ed6d758b463769a8cb7c',
        measurementId: 'G-5NGC3KX7NV',
      }
