/* eslint-disable */
function printInfo(title: string, info?: string): void {
  // eslint-disable-next-line
  console.log(
    `%c ${title} %c ${info} %c`,
    'background: #f26d21; padding: 1px; border-radius: 3px 0 0 3px; color: #fff',
    'background: #009eed; padding: 1px; border-radius: 0 3px 3px 0; color: #fff',
    'background: transparent'
  )
  // console.log(import.meta.env)
}

export default function printBuildInfo(): void {
  printInfo('Env', import.meta.env.VITE_ENV)
}
