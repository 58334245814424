// import '@Styles/App.css'
import '@Styles/index.css'
// import '@Styles/mui.css'
// import '@Styles/overrides.css'

import { lazy } from 'react'
import { createRoot } from 'react-dom/client'

import { CookieConsent } from '@Components/ui/CookieConsent'
import printBuildInfo from '@Core/utils/printBuildInfo'

const App = lazy(() => import(/* webpackChunkName: "App" */ './App'))
const ProviderWrapper = lazy(
  () => import(/* webpackChunkName: "ProviderWrapper" */ './ProviderWrapper')
) // Wrapped so we can Lazyload

printBuildInfo()
const container = document.getElementById('root') as Element
const root = createRoot(container)

root.render(
  // <StrictMode>
  <ProviderWrapper>
    <App />
    <CookieConsent />
  </ProviderWrapper>
  // </StrictMode>
)

// YT API
// handleClientLoad()
